import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { userSignOut } from "actions/Auth";
import { auth, firestore } from "../../firebase/firebase";

class CustomMenu extends React.Component {
  toggleCustomizer = () => {
    this.setState({ drawerStatus: !this.state.drawerStatus });
  };
  closeCustomizer = () => {
    this.setState({ drawerStatus: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      id_marca: "",
      nombre_marca: "",
      id_organizacion: "",
      drawerStatus: false,
    };
  }

  componentDidMount() {
    //se valida si el usuario está logueado
    auth.onAuthStateChanged((user) => {
      //console.log(user);
      if (user) {
        //console.log(newAttributes());
        firestore
          .collection("preferencias")
          .doc(user.uid)
          .get()
          .then((preferencia) => {
            this.setState({
              id_marca: preferencia.data().id_marca,
              nombre_marca: preferencia.data().nombre_marca,
              id_organizacion: preferencia.data().id_organizacion,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.props.userSignOut();
      }
    });
  }

  deleteLocalStorage = () => {
    console.log(3333);
    localStorage.removeItem("rango_fechas");
    localStorage.removeItem("filtros");
    localStorage.removeItem("id_dashboard");
    localStorage.removeItem("widgets");
    localStorage.removeItem("rango_fechas_respuestas");
    localStorage.removeItem("user_id");
    localStorage.removeItem("id_marca");
    localStorage.removeItem("nombre_marca");
    localStorage.removeItem("filtros_respuestas");
    localStorage.removeItem("currentMail");
    return true;
  };

  /**
   ** Método para logout
   **/
  signOut = () => {
    this.deleteLocalStorage();
    auth.signOut();
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {};

  render() {
    let display_items_preferencias = this.state.id_marca ? "block" : "none";

    return (
      <div className="theme-option">
        <IconButton
          className="btn-primary"
          onClick={this.toggleCustomizer.bind(this)}
        >
          <i className="material-icons text-white">reorder</i>
        </IconButton>
        <Drawer
          className="app-sidebar-content right-sidebar"
          anchor="right"
          open={this.state.drawerStatus}
          onClose={this.closeCustomizer.bind(this)}
        >
          <div className="color-theme">
            <div className="color-theme-header">
              <h3 className="color-theme-title">Menú </h3>

              <IconButton className="icon-btn" onClick={this.closeCustomizer}>
                <i className="zmdi zmdi-close text-white" />
              </IconButton>
            </div>
            <div className="color-theme-body">
              <ul className="nav-menu">
                <li
                  className={"menu"}
                  style={{ display: display_items_preferencias }}
                >
                  <NavLink
                    className=""
                    to={
                      "/app/marcas/alertas/" +
                      this.state.id_marca +
                      "/" +
                      this.state.id_organizacion
                    }
                    onClick={this.closeCustomizer.bind(this)}
                  >
                    <i className="material-icons">notification_important</i>
                    <span className="nav-text">Alertas</span>
                  </NavLink>
                </li>
                <Divider light />
                <li className={"menu"}>
                  <NavLink
                    className=""
                    to="/app/preferencias/preferencia"
                    onClick={this.closeCustomizer.bind(this)}
                  >
                    <i className="material-icons">room_preferences</i>
                    <span className="nav-text">Preferencias</span>
                  </NavLink>
                </li>
                <Divider
                  light
                  style={{ display: display_items_preferencias }}
                />
                <li className={"menu"}>
                  <NavLink className="" to="/signin" onClick={this.signOut}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
                    <span className="nav-text">Salir</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default connect(null, { userSignOut })(CustomMenu);
//export default withRouter(CustomMenu);
